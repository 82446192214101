import React, { useRef, useState, useContext } from "react"
import { Layout } from "@components/layout"
import {  Breadcrumbs, ResizedImage  } from "@components/shared"
import { Contact } from "@components/standard"
import { withHtml } from "@components/logic"

import {
  container,
  container__content,
} from "./styles/post.module.scss"

const Title = withHtml("h2")
const Content = withHtml("article")
const linkModifier = ({ slug }) => `/blog/${slug}/`

const Post = ({ pageContext }) => {
  const { page, nextPost, prevPost, products } = pageContext
  const { featuredImage, date, categories } = page
  const postCategory = categories.nodes[0]
  return (
    <Layout {...page}>
      <div className={container}>
        <Breadcrumbs
          elements={[
            {
              label: "Blog",
              url: `/blog/`,
            },
            {
              label: postCategory.name,
              url: `/blog/categories/${postCategory.slug}/`,
            },
          ]}
        />
        <div className={container__content}>
          <ResizedImage {...featuredImage.node} size="600w" />
          <Title>{page.title}</Title>
          <Content>{page.content}</Content>
        </div>
        <Contact />
      </div>
    </Layout>
  )
}

export default Post

